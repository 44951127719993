<template>
  <div>
  <v-dialog v-model="visible" max-width="600">
    <v-card v-if="data">  
      <v-card-title class="display-1">
        Registration Details
        <v-spacer/>
      </v-card-title>
      <v-alert v-if="event && event.verification_method === 'REG_DATA'" type="warning" tile>
        <h3>Data changes will be overwritten on upload</h3>
        <p>Please make sure to ONLY make changes here when you ALSO make them in the source. Otherwise changes might get reverted after a new upload.</p>
      </v-alert>
      <v-alert v-if="org" type="info" tile>
        <h3>Data changes take ~10-15 minutes to sync</h3>
        <p>Please note it might take up to 10-15 minutes for changes to get synchronized with all events in this organization.</p>
      </v-alert>
      <!-- <v-card-text class="mt-4 pb-0">
        <p>
          Please note: any changes you make to registration records will be overwritten when you do an Excel/CSV import!
        </p>
      </v-card-text> -->
      <v-card-text class="mt-4 pb-0">

        <v-form>
          <h3>Personal information</h3>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="data.given_name"
                label="Given Name"
                />
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="data.family_name"
                label="Family Name"
                />
            </v-col>
          </v-row>
          <v-text-field
            v-model="data.name"
            label="Full Name (optional)"
            :placeholder="generatedName"
            :persistent-placeholder="generatedName != null && generatedName.length > 0"
            />
          
          <h3>Contact Details</h3>
          <v-text-field
            v-model="data.email"
            label="Email"
            />
          
          <h3>Details</h3>
          <v-radio-group v-model="data.gender" row class="mt-0" label="Gender:" >
            <v-radio :label="$t('profile.edit.gender-notset')" :value="null"></v-radio>
            <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
            <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
          </v-radio-group>
          
          <v-text-field
            v-model="data.team"
            label="Team / group"
            />


          <v-btn large color="primary" @click="save" :loading="$store.getters.isLoading">{{ isNewRecord ? (org||!data.email ? 'Add': 'Invite') : 'Save'}}</v-btn>
        </v-form>
      </v-card-text>
      <br/><br/>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import orgManagerService from "@/services/orgManagerService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'

export default {
  name: "ProfileDetailsDialog",
  components: {
    DistanceTextArea,
    DurationTextArea,
  },
  props: {
      event: Object,
      org: Object,
  },
  data() {
    return {
      siteData: siteData,
      visible: false,
      code: null,
      data: null,
      isNewRecord: false,
    };
  },
  async mounted() {
  },
  methods: {
    async new() {
      this.visible = true;
      this.isNewRecord = true;
      this.code = this.$helpers.randomId();
      this.data = {
        // empty
      };
    },
    async edit(reg) {
      this.code = reg.v;
      this.isNewRecord = false;
      this.visible = true;
      this.data = {
        name: reg.n,
        given_name: reg.gn,
        family_name: reg.fn,
        email: reg.e,
        gender: reg.ge,
        bib: reg.b,
        team: reg.g,
        team_captain: reg.tc,
        city: reg.ci,
        state: reg.st,
        country: reg.cc,
        phone: reg.p,
        race_ids: reg.r_ids,
        category: reg.ca,
      };
    },

    async save() {
      var response = null;
      try {
        if (this.org) {
          response = (await orgManagerService.putRegistration(this.org.id, this.code, this.data));
        }
        else {
          response = (await eventManagerService.putRegistration(this.event.id, this.code, this.data));
        }
        if (this.code) {
        }
        else {
          //response = (await eventManagerService.postRegistration(this.eventId, this.data)).data;
        }
      }
      catch(ex) {
        console.error(ex);
        this.$toast.error('Error saving data. Please check all required fields.');
      }
      console.log('SAVE', response);
      if (response) {
        this.visible = false;
        this.$emit('change');
      }
    },

  },
  computed: {
    generatedName() {
      return this.data == null ? '' : `${this.data.given_name||''} ${this.data.family_name||''}`.trim();
    }
  },

};
</script>
<style lang="scss">
</style>

