<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-3 py-2 subtitle">
          Manage registration data
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="actions">
          <v-btn v-if="eventUtil.isProPlan() && !event.user_created && event.published" color="primary" class="mr-4" @click="showUploadRegDialog=true">
            <v-icon small class="mr-2">fa fa-upload</v-icon> 
            Upload
          </v-btn>
          <v-btn  v-if="event.published && event.send_invite_email && !event.user_created" class="mr-4" @click="reinviteUnconnected">
            <v-icon small class="mr-2">fadl fa fa-paper-plane</v-icon>
            Re-send invites
          </v-btn>
        </div>
      </v-toolbar>
      <v-divider/>

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-alert tile v-if="!event.verification" type="warning">This event does not require verification codes, anyone can join. Click Edit to change this setting.</v-alert>
        <v-alert v-if="tenant.id==='cofi' && event.org && !event.user_created" type="info" tile>
          <p>
            This event is part of the <router-link class="white--text" :to="{name: 'orgmanagerView', params: {id: event.org.id}}">{{event.org.name}}</router-link> organization.
          </p>
          <p>
            Registration data is automatically synced (every 10-15 minutes) from the organization starting a week before the event opens until it is finished.
          </p>
          <p>
            <v-btn outlined color="" @click="forceSyncRegistrations">Force Sync</v-btn>            
          </p>
        </v-alert>
        <v-alert v-if="event.user_created" type="info" tile>
          <p>This is a user-created event. The organizer may invite others to join using the generic join link and QR code.</p>
          <p>Registration data is not available to user-created events.</p>
        </v-alert>
        <v-alert v-else-if="event.sync" type="success" tile>
          <p>This event is automatically syncing registration records. </p>
          <p>Last sync was at {{ event.sync.timestamp | localDate('ddd L LTS Z') }}: <strong>{{ event.sync.msg }}</strong></p>
        </v-alert>
        <v-alert v-else-if="!eventUtil.isProPlan()" type="info" tile>
          <p><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to be able to import an Excel file.</p>
        </v-alert>

        <v-card-text v-if="tenant.isDefault">      
          <p >
            Please read our <a href="https://sodisp.freshdesk.com/support/solutions/folders/77000137491" target="_blank">Frequently asked questions</a> on creating and managing events for tips and tricks.
          </p>
          <p v-if="false && tenant.id == 'cofi'">
            Share this url with your participants to help them get started:<br/>
            <code>{{eventUtil.gettingStartedUrl()}}</code>
            <v-btn text color="accent" small @click="$helpers.copyToClipboard(eventUtil.gettingStartedUrl(), $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
          </p>

          <v-expansion-panels v-if="tenant.id !== 'cofi'" class="mb-4">
          <v-expansion-panel>
            <v-expansion-panel-header class="v-card__title subtitle">Show Leaderboard Mapping Details</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Use these values to assign participants to specific leaderboards. Make sure your import file contains the <strong>exact</strong> value as it's shown in the 'Import ID' column.</p>
              <p>You can edit those values by Editing your event, open the leaderboard details and expand the Advanced panel.</p>
              <v-data-table 
                :headers="raceHeaders"
                :items="event.races"
                :items-per-page="15"
                >
                <template v-slot:item.import_id="{ item }">
                  {{ item.import_id || item.id }}
                </template>

              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="v-card__title subtitle">Preloaded activities</v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3>Upload preloaded activities</h3>
              <p>
                When you are moving an active challenge over from another platform to {{tenant.name}} you can preload activity data from your current challenge so that all data will be available when a participants switches to {{tenant.name}}!
              </p>
              <v-dialog v-model="preloadActivitiesDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <p><v-btn outlined color="red" v-on="on"><v-icon small class="mr-2">fa fa-upload</v-icon> Upload data</v-btn></p>
                </template>
                <v-card>
                  <v-card-title>Upload data from RunSignup</v-card-title>
                  <v-card-text>
                    You can download an Excel file with existing activities from your RunSignup page. This file can be selected here to have all preloaded.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <ExcelUploadForm :event-id="event.id" @uploaded="preloadActivitiesDialog=false;loadData()" type="PRELOADED_ACTIVITIES"/>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="preloadActivitiesDialog = false">{{$t('shared.close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>  
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <v-alert v-if="preloadedActivities && preloadedActivities.status == 'INFO'" type="info">{{ preloadedActivities.msg }}</v-alert>

          <div v-if="false && event.send_invite_email">
            <h3>Resend invites</h3>
            <p>New registrants will automatically receive an invite email to join this event. We ensure the invites are only send to new registrations.</p>
            <p>Optionally, you can trigger sending invite emails to everyone who hasn't connected yet.</p>
            <v-btn color="info" outlined @click="reinviteUnconnected">
              <v-icon class="mr-2">fa-paper-plane</v-icon>
              Re-send invite emails
            </v-btn>
          </div>
        </v-card-text>

        <v-dialog v-if="eventUtil.isProPlan() && !event.org" v-model="showUploadRegDialog" max-width="600px">
          <!-- <template v-slot:activator="{ on }">
            <p><v-btn large color="primary" v-on="on"><v-icon small class="mr-2">fa fa-upload</v-icon> Upload Registration Data</v-btn></p>
          </template> -->
          <v-card>
            <v-card-title>Upload Registration Data</v-card-title>
            <v-alert v-if="event.send_invite_email" type="info" tile>This will automatically send invites to all new users in the file.</v-alert>
            <v-card-text>
              <p>
                Please upload a csv or Excel (.xlsx) file with valid data. Note: only full imports (not incremental) are supported currently. 
                Download a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-template.xlsx">template Excel file</a> or a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-sample.xlsx">Sample Excel file</a>.
              </p>
              <p>
                Optionally, you can manage <router-link v-if="event.verification" :to="{name:'eventmanagerAllowlist', params: {id: event.id}}">manage verification codes</router-link>
                to be used (in combination with) registration data.
              </p>
              <CsvUploadForm :event-id="event.id" @uploaded="uploaded"/>
            </v-card-text>
          </v-card>
        </v-dialog>            
          
        <v-card-title class="subtitle pt-4">
          <span v-if="$store.getters.isLoading" class="mr-1">  
            <v-icon small>fa fa-circle-notch fa-spin</v-icon>
          </span>
          <span v-else class="mr-1">
            {{registrations.length}} 
          </span>
          registrations
          <v-btn v-if="tenant.id === 'cofi' || true" outlined color="primary" class="ml-4 mr-0" @click="$refs.detailsDialog.new()">
            <v-icon class="mr-2">fa-plus</v-icon> Add
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table v-if="registrations"
          :headers="headers"
          :items="registrations"
          :items-per-page="15"
          :search="search"
          >
          <template v-slot:item.actions="{ item }" style="min-width:100px;">
            <v-icon small color="primary" title="Copy invite link to clipboard" class="" @click="copyInviteLink(item.v)">fal fa-copy</v-icon>
            <v-icon v-if="eventUtil.hasDeeplinking()" small color="primary" title="Show QR" class="ml-2" @click="showQr(item.v)">fal fa-qrcode</v-icon>
            <v-icon v-if="tenant.id === 'cofi' || true || event.verification_method === 'ALLOWLIST'" small color="primary" class="ml-2" @click="$refs.detailsDialog.edit(item)">
              fal fa-user-edit
            </v-icon>
            <v-icon v-if="item.e" small color="primary" class="ml-2" title="Re-send invite email" @click="resendInvitation(item.v)">fal fa-envelope</v-icon>
          </template>
          <template v-slot:item.quickentry="{ item }">
            <a :href="`${tenant.homeUrl}events/${event.id}/quickentry?code=${item.v||''}&email=${item.e||''}`" target="_blank">Open</a>
            <v-icon small color="primary" title="Copy quick entry link to clipboard" class="ml-2" @click="$helpers.copyToClipboard(`${tenant.homeUrl}events/${event.id}/quickentry?code=${item.v||''}&email=${item.e||''}`, $toast)">fal fa-copy</v-icon>
          </template>
          <template v-slot:item.v="{ item }">
            {{ item.v }}
          </template>
          <template v-slot:item.join_date="{ item }">
            {{ item.join_date | localDate('L LT') }}
          </template>
          <template v-slot:item.e="{ item }">
            {{ item.e }}
          </template>
          <template v-slot:item.g="{ item }">
            <v-icon small v-if="!item.g && item.r_id && (event.races.find(x => x.id == item.r_id) || {}).team" color="warning" title="Team name missing.">fadl fa fa-exclamation-triangle</v-icon>
            {{ item.g }}
          </template>
          <template v-slot:item.r="{ item }">
            <v-icon small v-if="item.r && !item.r_ids" color="warning" title="Could not map the value in the import file to a leaderboard. Please configure the Import ID value in the Leaderboard Edit screen.">fadl fa fa-exclamation-triangle</v-icon>
            <v-icon small v-if="item.r_ids && item.r_ids.length" color="success" title="This leaderboard is found and mapped.">fadl fa fa-check</v-icon>
            {{ item.r }}
            <span v-if="item.r_ids && item.r_ids.length" class="text-muted" :title="item.r_ids.join(', ')">({{item.r_ids.length}}x)</span>
            <span v-if="!item.r_ids" class="text-muted">All leaderboards</span>
          </template>
        </v-data-table>
        <br/>

      </div>      
      <RegistrationDetailsDialog :event="event" ref="detailsDialog" @change="loadRegistrations"/>
      <v-dialog v-if="joinInfo" v-model="showQrJoinCode" max-width="400px">
        <v-card>
          <v-card-title>Join Event QR</v-card-title>
          <v-card-text>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</v-card-text>
          <v-card-text>This is the <strong>personal</strong> join link for code <strong>{{joinInfo.join_code}}</strong> and can only be used once.</v-card-text>
          <img :src="`https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=${encodeURIComponent(joinInfo.join_url)}`" width="400" height="400"/>
        </v-card>
      </v-dialog>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import CsvUploadForm from "@/components/CsvUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import RegistrationDetailsDialog from "@/components/RegistrationDetailsDialog";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    CsvUploadForm,
    ExcelUploadForm,
    RegistrationDetailsDialog,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      registrations: [],
      preloadActivitiesDialog: false,
      preloadedActivities: null,
      search: null,
      showQrJoinCode: false,
      joinInfo: null,
      showUploadRegDialog: false,
      headers: [
        { text: 'Actions', sortable: false, value: 'actions', class: 'col-min100'},
        { text: 'QuickEntry', sortable: false, value: 'quickentry', class: 'col-min100'},
        { text: 'Code', align: 'start', sortable: true, value: 'v',},
        { text: 'Bib', align: 'start', sortable: true, value: 'b',},
        { text: 'Full name', align: 'start', sortable: true, value: 'n',},
        { text: 'First name', align: 'start', sortable: true, value: 'gn',},
        { text: 'Last name', align: 'start', sortable: true, value: 'fn',},
        { text: 'Team', align: 'start', sortable: true, value: 'g',},
        { text: 'Leaderboard(s)', align: 'start', sortable: true, value: 'r',},
        { text: 'Gender', align: 'start', sortable: true, value: 'ge',},
        { text: 'Category', align: 'start', sortable: true, value: 'ca',},
        { text: 'Email', align: 'start', sortable: true, value: 'e',},
        { text: 'Phone', align: 'start', sortable: true, value: 'p',},
        { text: 'City', align: 'start', sortable: true, value: 'ci',},
        { text: 'State', align: 'start', sortable: true, value: 'st',},
        { text: 'Country', align: 'start', sortable: true, value: 'cc',},
        { text: 'Captain?', align: 'start', sortable: true, value: 'tc',},
      ],
      raceHeaders: [
        { text: 'Leaderboard', value: 'name', sortable: true, },
        { text: 'Import ID', value: 'import_id', sortable: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      await this.loadRegistrations();
      this.preloadedActivities = (await eventManagerService.getPreloadedActivitiesInfo(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async uploaded() {
      this.showUploadRegDialog = false;
      this.loadData();
    },

    async loadRegistrations() {
      this.registrations = (await eventManagerService.getRegistrations(this.event.id)).data.data || [];
    },

    async forceSyncRegistrations() {
      const response = (await eventManagerService.forceRegistrationSync(this.event.id));
      this.$helpers.toastResponse(this, response.data, 'Sync requested, it might take a minute to complete. Refresh the page to see the results.');
    },

    async resendInvitation(code) {
      if (confirm(`Are you sure you want to resend the invitation to ${code}?`)) {
        const response = (await eventManagerService.sendInviteEventEmailToCode(this.event.id, code)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },
    async reinviteUnconnected() {
      if (confirm(`Are you sure you want to resend the invitation to ALL registrants who have not yet joined?`)) {
        const response = (await eventManagerService.reinviteUnconnected(this.event.id)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },

    async showQr(code) {
      this.joinInfo = (await eventManagerService.getJoinInfo(this.event.id, code)).data;
      this.showQrJoinCode = true;      
    },

    async copyInviteLink(code) {
      const joinInfo = (await eventManagerService.getJoinInfo(this.event.id, code)).data;
      //prompt(`Select and copy this link to your clipboard:`, `https://${window.location.hostname}/register?event=${this.event.id}&verificationCode=${code}`);
      this.$helpers.copyToClipboard(joinInfo.join_url, this.$toast);
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Registration data', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .col-min100 { min-width: 100px;}
</style>

